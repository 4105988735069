import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade, Thumbs } from 'swiper/modules';
import 'swiper/css';

window.addEventListener('turbo:load', () => {
  
  var swiperPartners = new Swiper('.partners-slider', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    speed: 3500,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  });

  var myAuctionSwiper = new Swiper('.auction-slider ', {
    modules: [Pagination, Navigation, Autoplay],
    loop: true,
    speed: 800,
    slidesPerView: "1",
    spaceBetween: 32,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  });

  var myInstockSwiper = new Swiper('.instock-slider ', {
    modules: [Pagination, Navigation, Autoplay],
    loop: true,
    speed: 800,
    slidesPerView: "1",
    spaceBetween: 32,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 3,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  });

  var myFeedbacksSwiper = new Swiper('.feedbacks-slider ', {
    modules: [Pagination, Navigation, Autoplay],
    loop: true,
    speed: 800,
    slidesPerView: "1",
    spaceBetween: 32,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  });

  var galleryCarSlider = new Swiper('.gallery-slider', {
    slidesPerView: "auto",
    slidesPerGroup: 1,
    spaceBetween: 24
  });

  var showCarSlider = new Swiper('.show-slider', {
    modules: [Navigation, Thumbs],
    slidesPerView: "1",
    spaceBetween: 4,
    navigation: {
      nextEl: '.show-swiper-button.next',
      prevEl: '.show-swiper-button.prev',
    },
    thumbs: {
      swiper: galleryCarSlider,
    },
  });
});
