window.addEventListener('turbo:load', () => {
  const images = document.querySelectorAll('.not-found .images img');
  let currentIndex = 1;

  setInterval(() => {
    images.forEach((img, index) => {
      img.classList.toggle('active', index === currentIndex);
    });

    currentIndex = (currentIndex + 1) % images.length;
  }, 500);
});
