document.addEventListener('turbo:load', function () {
  var filterBtn = document.querySelector('.filters-open');
  var filter = document.querySelector('.filters-wrapper');
  var closeBtn = document.querySelector('.close-filters');
  var body = document.querySelector('body');

  if (filterBtn) {
    filterBtn.addEventListener('click', function (event) {
      event.stopPropagation();
      filter.classList.add('active');
      body.style.overflow = 'hidden';
    });

    closeBtn.addEventListener('click', function (event) {
      event.stopPropagation();
      filter.classList.remove('active');
      body.style.overflow = '';
    });

    document.addEventListener('click', function (event) {
      var isClickInsideFilter = filter.contains(event.target);
      var isClickOnFilterBtn = filterBtn.contains(event.target);
      if (!isClickInsideFilter && !isClickOnFilterBtn) {
        filter.classList.remove('active');
        body.style.overflow = '';
      }
    });
  }
});