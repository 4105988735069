import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import "./controllers"

require("./packs/header")
require("./packs/slider")
require("./packs/catalogue")
require("./packs/fancybox")
require("./packs/copy")
require("./packs/error")
require("./packs/support")
require("./packs/popup")
require("./packs/change_logo")
require("./packs/stages")
