document.addEventListener("DOMContentLoaded", function () {
  const images = document.querySelectorAll(".image-change img");

  if (images.length === 0) {
    return;
  }

  let currentIndex = 0;

  function showNextImage() {
    images.forEach(img => {
      img.classList.remove('show');
    });
    images[currentIndex].classList.add('show');
    currentIndex = (currentIndex + 1) % images.length;
  }

  setInterval(showNextImage, 2000);

  showNextImage();
});

document.addEventListener("DOMContentLoaded", function () {
  const imagesBrands = document.querySelectorAll(".image-change-brands img");

  if (imagesBrands.length === 0) {
    return;
  }

  let currentIndex = 0;

  function showNextImageBrands() {
    imagesBrands.forEach(img => {
      img.classList.remove('show');
    });
    imagesBrands[currentIndex].classList.add('show');
    currentIndex = (currentIndex + 1) % imagesBrands.length;
  }

  setInterval(showNextImageBrands, 2000);

  showNextImageBrands();
});